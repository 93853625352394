<template>
  <v-app id="app">
    <v-app-bar app color="primary" dark flat class="mx-auto" height="80px" fixed>
      <v-img :src="require('@/assets/Unija_logo.svg')"
             max-height="28px"
             contain
             @click="goToHome"></v-img>
    </v-app-bar>
    <v-main app class="d-flex">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "unauthenticated",
  methods: {
    async goToHome() {
      if (this.$route.path !== '/') await this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>
